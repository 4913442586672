import * as React from 'react';

// @ts-ignore
import * as styles from './Alert.module.css';
import CustomButton from '../CustomButton/CustomButton';

interface IAlert {
    message: string
    dismissLabel: string
    dismiss: () => void
}

const Alert = ({
    message,
    dismissLabel,
    dismiss,
}: IAlert) => {
    return <>
        <div className={styles.background}/>
        <div className={styles.container}>
            <h4>{message}</h4>
            <CustomButton
                label={dismissLabel}
                onClick={dismiss}
            />
        </div>
    </>;
};

export default Alert;
