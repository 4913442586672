import * as React from 'react';
import {
    connect
} from 'react-redux';
import CustomButton from '../../CustomButton/CustomButton';
import FileInput from '../../formElements/FileInput/FileInput';

// @ts-ignore
import * as styles from './Documents.module.css';

const Documents = ({
    setActiveTab,
}) => {
    return <div className={`${styles.inputs} `}>
        <FileInput
            label="Pitchdeck (PDF)"
            name="documentsPitchDeck"
            allowedTypes=".pdf"
        />
        <FileInput
            label="Logo (JPEG / PNG / SVG)"
            name="documentsLogo"
            allowedTypes="image/*"
        />
        <FileInput
            label="Foto des Gründungsteams (JPEG / PNG)"
            name="documentsFoundationTeamPhotos"
            allowedTypes="image/*"
        />
        <FileInput
            label="Foto des Teams (optional) (JPEG / PNG)"
            name="documentsTeamPhotos"
            allowedTypes="image/*"
        />
        <FileInput
            label="Bild der Innovation (JPEG / PNG)"
            name="documentsProductPhotos"
            allowedTypes="image/*"
        />

        {/* TODO: @Felix, der Button wird am unteren Seitenende zentriert */}
        <CustomButton
            label='Weiter zu "Kontakt"'
            onClick={() => {
                setActiveTab('contact');
            }}
        />
    </div>;
};

const mapStateToProps = ({}) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setApplication: ({
            application,
        }) => {
            return dispatch({
                type: 'SET_APPLICATION',
                payload: {
                    application,
                },
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Documents);


