import * as React from 'react';
import {
    ErrorMessage, Field, useFormikContext
} from 'formik';
import {
    BaseSyntheticEvent
} from 'react';
// @ts-ignore
import * as styles from './DateInput.module.css';

interface ITextInput {
    label: string,
    value?: number,
    defaultValue?: number,
    name: string,
    // eslint-disable-next-line no-unused-vars
    onBlur?: (event: BaseSyntheticEvent, target) => void,
}

// const createIntegerRange = (
//     from, to
// ) => {
//     from = parseInt(from);
//     to = parseInt(to);
//     return Array.from(
//         Array(to - from + 1).keys(),
//         n => {
//             return n + from;
//         }
//     );
// };

const DateInput = ({
    label, value, name, onBlur,
}: ITextInput) => {
    // const [hiddenValue, setHiddenValue,] = useState(defaultValue);

    // const day = useRef(null);
    // const month = useRef(null);
    // const year = useRef(null);
    // const hiddenField = useRef(null);

    // const handleChange = () => {
    //     const date = new Date();
    //     date.setDate(parseInt(
    //         day.current.value,
    //         10
    //     ));
    //     date.setMonth(parseInt(
    //         month.current.value,
    //         10
    //     ) - 1);
    //     date.setFullYear(parseInt(
    //         year.current.value,
    //         10
    //     ));
    //     setHiddenValue(date.getTime());
    // };

    // useEffect(
    //     () => {
    //         if (hiddenValue !== defaultValue) {
    //             onBlur(
    //                 null,
    //                 hiddenField.current
    //             );
    //             console.log(
    //                 'set date',
    //                 hiddenValue,
    //                 defaultValue
    //             );
    //         }
    //     },
    //     [hiddenValue,]
    // );

    const today = `${(new Date()).getFullYear()}-${String((new Date()).getMonth() + 1).padStart(
        2,
        '0'
    )}-${String((new Date()).getDate()).padStart(
        2,
        '0'
    )}`;

    const {
        initialValues,
    } = useFormikContext();
    const defaultValue = initialValues[name];
    return <div className={styles.dateInput}>
        <label>
            <span>{label}:</span>
            <ErrorMessage
                className="invalid"
                name={name}
                component="div" />
            <Field
                type="date"
                name={name}
                defaultValue={defaultValue}
                value={value}
                onBlur={onBlur}
                max={today}
            />
        </label>
    </div>;

    // return <div>
    //     <label>
    //         <span>{label}:</span>
    //         <ErrorMessage className="invalid" name={name} component="div"/>
    //         <input
    //             name={name}
    //             type="hidden"
    //             value={hiddenValue}
    //             ref={hiddenField}/>
    //         <select
    //             ref={day}
    //             onChange={handleChange}
    //             value={(new Date(defaultValue)).getDate()}
    //         >
    //             {createIntegerRange(
    //                 1,
    //                 31
    //             ).map(day => {
    //                 return <option key={`day${day}`} value={day}>{day}</option>;
    //             })}
    //         </select>
    //         <select
    //             ref={month}
    //             onChange={handleChange}
    //             value={(new Date(defaultValue)).getMonth() + 1}
    //         >
    //             {createIntegerRange(
    //                 1,
    //                 12
    //             ).map(month => {
    //                 return <option key={`month${month}`} value={month}>{month}</option>;
    //             })}
    //         </select>
    //         <select
    //             ref={year}
    //             onChange={handleChange}
    //             value={(new Date(defaultValue)).getFullYear()}
    //         >
    //             {createIntegerRange(
    //                 1800,
    //                 (new Date).getFullYear()
    //             ).reverse().map(year => {
    //                 return <option key={`year${year}`} value={year}>{year}</option>;
    //             })}
    //         </select>
    //     </label>
    // </div>;
};

export default DateInput;
