import * as React from 'react';
import {
    ErrorMessage, Field
} from 'formik';
import {
    BaseSyntheticEvent, useState
} from 'react';
import {
    deleteFile,
    uploadFile
} from '../../../api/application';
import Alert from '../../Alert/Alert';
import CustomButton from '../../CustomButton/CustomButton';
import {
    apiDomain
} from '../../../api/api';
import {
    getCookie
} from '../../../helpers/cookie';
import {
    BEARER_TOKEN_KEY
} from '../../../helpers/user';
// @ts-ignore
import * as styles from './FileInput.module.css';
import Confirmation from '../../Confirmation/Confirmation';
import {
    connect
} from 'react-redux';

interface IFileInput {
    label: string,
    // defaultValue?: {
    //     path: string,
    //     original_file_name: string,
    // },
    name: string,
    // eslint-disable-next-line no-unused-vars
    multiple?: boolean,
    allowedTypes?: string
    // eslint-disable-next-line no-unused-vars
    setApplication: (application) => void,
    application: any
}

const FileInput = ({
    label, name, multiple = false, allowedTypes, setApplication, application,
}: IFileInput) => {
    const [alert, setAlert,] = useState(null);
    const [confirmation, setConfirmation,] = useState(null);
    const [isUploading, setIsUploading,] = useState(false);
    const defaultValue = application ? application.data[name] : null;

    const handleChange = async (e: BaseSyntheticEvent) => {
        const files = e.target.files as FileList;
        if (0 === files.length) {
            return;
        }
        setIsUploading(true);
        // @ts-ignore
        for (const file of files) {
            if ('.pdf' === allowedTypes && 'application/pdf' !== file.type) {
                setAlert('Nur PDF Dateien sind erlaubt.');
                setIsUploading(false);
                return;
            }
            if ('image/*' === allowedTypes && 'false' === file.type.startsWith('image/')) {
                setAlert('Nur Bild Dateien sind erlaubt.');
                setIsUploading(false);
                return;
            }
            if (file.size > 20 * 1024 * 1024) {
                setAlert('Die Dateigröße darf 20 MB nicht überschreiten');
                setIsUploading(false);
                return;
            }
            const result = await uploadFile(
                name,
                file
            );
            if (false === result.success) {
                setAlert(result.message);
            }
            if (result.success) {
                setApplication(result);
            }

            setIsUploading(false);
        }
    };

    if (alert) {
        return <Alert
            message={alert}
            dismissLabel="Schließen"
            dismiss={() => {
                setAlert(null);
            }}
        />;
    }

    if (confirmation) {
        return confirmation;
    }

    if (false === multiple && defaultValue) {
        return <div>
            <span>{label}:</span>
            <div className={styles.row}>
                <a
                    target="_blank"
                    href={`${apiDomain}/api/display-file/${defaultValue.path}?api_token=${getCookie(BEARER_TOKEN_KEY)}`}>{defaultValue.original_file_name}
                </a>
                <CustomButton
                    style="link"
                    label="Löschen"
                    onClick={() => {
                        setConfirmation(<Confirmation
                            confirm={async() => {
                                const result = await deleteFile(
                                    name,
                                    defaultValue.path
                                );
                                if (result.success) {
                                    setApplication({
                                        application: result.application,
                                    });
                                }
                                setConfirmation(null);
                            }}
                            confirmLabel="Löschen"
                            dismiss={() => {
                                setConfirmation(null);
                            }}
                            dismissLabel="Abbrechen"
                            message={`Möchten Sie die Datei ${defaultValue.original_file_name} wirklich löschen?`}/>);
                    }}/>
            </div>
        </div>;
    }

    if (isUploading) {
        return <div>
            <div className={styles.isUploadingBackground}/>
            <div className={styles.isUploadingContainer}>
                <h4>Datei wird hochgeladen</h4>
                <div className={styles.progressIndicator}/>
            </div>
        </div>;
    }

    return <div>
        <label>
            <span>{label}:</span>
            <div>
                <ErrorMessage
                    className="invalid"
                    name={name}
                    component="div"/>
                {
                    defaultValue &&
                    <>
                        <a href={defaultValue.path}>{defaultValue.original_file_name}</a>
                        <CustomButton
                            style="link"
                            label="Löschen"
                            onClick={() => {
                            }}/>
                    </>
                }
                <div className={styles.inputWrapper}>
                    <Field
                        type="file"
                        name={name}
                        multiple={multiple}
                        onChange={handleChange}
                        accept={allowedTypes}
                        className={styles.input}
                    />
                    <div className={styles.inputStyled}>Datei hochladen</div>
                </div>
            </div>
        </label>
    </div>;
};

const mapStateToProps = ({
    application,
}) => {
    return {
        application,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setApplication: ({
            application,
        }) => {
            return dispatch({
                type: 'SET_APPLICATION',
                payload: {
                    application,
                },
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FileInput);
