import * as React from 'react';
import {
    connect
} from 'react-redux';
import {
    updateValue
} from '../../../pages/application';
import CustomButton from '../../CustomButton/CustomButton';
import RadioButtons from '../../formElements/RadioButtons/RadioButtons';
import CheckboxGroup from '../../formElements/CheckboxGroup/CheckboxGroup';
import TextArea from '../../formElements/TextArea/TextArea';
import NumberInput from '../../formElements/NumberInput/NumberInput';

const Finances = ({
    application,
    setApplication,
    setActiveTab,
}) => {
    const onFieldBlur = async (
        event, target = null
    ) => {
        await updateValue(
            event,
            target,
            setApplication
        );
    };

    return <div className="">
        <RadioButtons
            label="Haben Sie eine Finanzierung erhalten?"
            name="financesFundingReceived"
            onChange={onFieldBlur}
            options={[{
                value: 'y', label: 'Ja',
            }, {
                value: 'n', label: 'Nein',
            },]}
        />

        {
            'y' === application?.data.financesFundingReceived &&
            <>
                <RadioButtons
                    label="Höhe der Finanzierung"
                    name="financesFundingAmount"
                    onChange={onFieldBlur}
                    options={[
                        {
                            value: '10000', label: '0 - 10.000 Euro',
                        },
                        {
                            value: '25000', label: '10.000 - 25.000 Euro',
                        },
                        {
                            value: '50000', label: '25.000 - 50.000 Euro',
                        },
                        {
                            value: '100000', label: '50.000 - 100.000 Euro',
                        },
                        {
                            value: '500000', label: '100.000 - 500.000 Euro',
                        },
                        {
                            value: '>500000', label: 'Mehr als 500.000 Euro',
                        },
                    ]}

                />

                <CheckboxGroup
                    label="Finanzierungsgeber"
                    description="Mehrfachnennung möglich"
                    name="financesFundingFinancier"
                    onChange={onFieldBlur}
                    defaultValueKey={'financesFundingFinancier'}
                    defaultValueData={application?.data}
                    options={[
                        {
                            value: 'Bund', label: 'Bund',
                        },
                        {
                            value: 'Land', label: 'Land',
                        },
                        {
                            value: 'Venture Capital', label: 'Venture Capital',
                        },
                        {
                            value: 'Familie / Freunde', label: 'Familie / Freunde',
                        },
                        {
                            value: 'Unternehmenspartner', label: 'Unternehmenspartner',
                        },
                        {
                            value: 'Mutterunternehmen', label: 'Mutterunternehmen',
                        },
                        {
                            value: 'Bankkredit', label: 'Bankkredit',
                        },
                        {
                            value: 'Sonstiges', label: 'Sonstiges',
                        },
                    ]}

                />
            </>
        }
        {
            'y' === application?.data.financesFundingReceived &&
            'Sonstiges' === application?.data['financesFundingFinancier[Sonstiges]'] &&
            <TextArea
                label='Begründen Sie Ihre Auswahl "Sonstiges"'
                name="financesFundingFinancierOther"
                onBlur={onFieldBlur}
                maxChars={50}
            />
        }

        <NumberInput
            label="Geplantes Umsatzlevel in Euro für 2021 in Euro"
            name="financesTurnover2021"
            onBlur={onFieldBlur}
        />

        <NumberInput
            label="Geplantes Umsatzlevel in Euro für 2022 in Euro"
            name="financesTurnover2022"
            onBlur={onFieldBlur}
        />

        <NumberInput
            label="Geplantes Umsatzlevel in Euro für 2023 in Euro"
            name="financesTurnover2023"
            onBlur={onFieldBlur}
        />

        {/* TODO: @Felix, der Button wird am unteren Seitenende zentriert */}
        <CustomButton
            label='Weiter zu "Potential"'
            onClick={() => {
                setActiveTab('potential');
            }}
        />
    </div>;
};

const mapStateToProps = ({
    application,
}) => {
    return {
        application,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setApplication: ({
            application,
        }) => {
            return dispatch({
                type: 'SET_APPLICATION',
                payload: {
                    application,
                },
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Finances);


