import * as React from 'react';
import {
    connect
} from 'react-redux';
import {
    updateValue
} from '../../../pages/application';
import TextArea from '../../formElements/TextArea/TextArea';
import CustomButton from '../../CustomButton/CustomButton';
import TextInput from '../../formElements/TextInput/TextInput';
import RadioButtons from '../../formElements/RadioButtons/RadioButtons';

const Innovation = ({
    setApplication,
    setActiveTab,
}) => {
    const onFieldBlur = async (
        event, target = null
    ) => {
        await updateValue(
            event,
            target,
            setApplication
        );
    };
    return <div className="">
        <TextInput
            label="Name der Innovation"
            name="innovationName"
            onBlur={onFieldBlur}
        />

        <TextArea
            label="Kurzbeschreibung der Innovation"
            description="Bitte beschreiben Sie Ihr Produkt oder Ihre Dienstleistung in einem Satz max. 25 Wörter"
            name="innovationShortDescription"
            onBlur={onFieldBlur}
            maxWords={25}
        />

        <TextArea
            label="Ausführliche Beschreibung der Innovation"
            description="Bitte beschreiben Sie Ihr Produkt oder Ihre Dienstleistung ausführlicher max. 150 Wörter"
            name="innovationDescription"
            onBlur={onFieldBlur}
            maxWords={150}
        />

        <RadioButtons
            label="Ist Ihr Produkt / Ihre Dienstleistung Patent / IP geschützt?"
            name="innovationProtected"
            onChange={onFieldBlur}
            options={[{
                value: 'y', label: 'Ja',
            }, {
                value: 'n', label: 'Nein',
            },]}
        />

        <TextArea
            description="Bitte erklären Sie was genau patentiert ist max. 100 Wörter"
            name="innovationProtectionDescription"
            onBlur={onFieldBlur}
            maxWords={100}
        />

        <TextArea
            label="Kundennutzen"
            description="Bitte erklären Sie die Vorteile Ihres Produktes / Ihrer Dienstleistung für den Kunden max. 100 Wörter"
            name="innovationAdvantages"
            onBlur={onFieldBlur}
            maxWords={100}
        />

        <TextArea
            label="Alleinstellungsmerkmal"
            description="Bitte erklären Sie was Ihr Produkt / Ihre Dienstleistung auszeichnet max. 100 Wörter"
            name="innovationUSP"
            onBlur={onFieldBlur}
            maxWords={100}
        />

        {/* TODO: @Felix, der Button wird am unteren Seitenende zentriert */}
        <CustomButton
            label='Weiter zu "Kapital"'
            onClick={() => {
                setActiveTab('finances');
            }}
        />
    </div>;
};

const mapStateToProps = ({
    application,
}) => {
    return {
        application,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setApplication: ({
            application,
        }) => {
            return dispatch({
                type: 'SET_APPLICATION',
                payload: {
                    application,
                },
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Innovation);


