import * as React from 'react';
import {
    connect
} from 'react-redux';
import {
    updateValue
} from '../../../pages/application';
import CustomButton from '../../CustomButton/CustomButton';
import TextInput from '../../formElements/TextInput/TextInput';
import FieldSet from '../FieldSet/FieldSet';
import Select from '../../formElements/Select/Select';
import EmailInput from '../../formElements/EmailInput/EmailInput';

const Contact = ({
    application,
    setApplication,
    setActiveTab,
}) => {
    const onFieldBlur = async (
        event, target = null
    ) => {
        await updateValue(
            event,
            target,
            setApplication
        );
    };
    return <div className="">
        <FieldSet
            label="Einreicher"
            replicateDefaultValues={application?.data}
            setApplication={setApplication}
            fields={[
                <TextInput
                    label="Titel (optional)"
                    name="contactTitle"
                    onBlur={onFieldBlur}
                />,
                <Select
                    label="Anrede"
                    name="contactSalutation"
                    onBlur={onFieldBlur}
                    options={[
                        {
                            value: '', label: 'Nicht ausgewählt',
                        },
                        {
                            value: 'w', label: 'Frau',
                        },
                        {
                            value: 'm', label: 'Herr',
                        },
                        {
                            value: 'd', label: 'Divers',
                        },
                    ]}
                />,
                <TextInput
                    label="Vorname"
                    name="contactFirstName"
                    onBlur={onFieldBlur}
                />,
                <TextInput
                    label="Nachname"
                    name="contactLastName"
                    onBlur={onFieldBlur}
                />,
                <EmailInput
                    label="E-Mail"
                    name="contactEmail"
                    onBlur={onFieldBlur}
                />,
                <TextInput
                    label="Telefonnummer"
                    name="contactPhone"
                    onBlur={onFieldBlur}
                />,
            ]}/>

        {/* TODO: @Felix, der Button wird am unteren Seitenende zentriert */}
        <CustomButton
            label='Weiter zu "Abschicken"'
            onClick={() => {
                setActiveTab('submit');
            }}
        />
    </div>;
};

const mapStateToProps = ({
    application,
}) => {
    return {
        application,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setApplication: ({
            application,
        }) => {
            return dispatch({
                type: 'SET_APPLICATION',
                payload: {
                    application,
                },
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Contact);


