import * as React from 'react';
import {
    navigate
} from 'gatsby';
import {
    BaseSyntheticEvent,
    useEffect, useState
} from 'react';
import DefaultPage from '../components/layouts/DefaultPage';
import {
    connect
} from 'react-redux';
import Tab from '../components/Tabs/Tab';
import Tabs from '../components/Tabs/Tabs';
import Progress from '../components/Progress/Progress';
import {
    Form,
    Formik
} from 'formik';
import Company from '../components/forms/Company/Company';
import {
    fetchApplication,
    updateApplicationValue
} from '../api/application';
import Innovation from '../components/forms/Innovation/Innovation';
import Finances from '../components/forms/Finances/Finances';
import Potential from '../components/forms/Potential/Potential';
import Documents from '../components/forms/Documents/Documents';
import Contact from '../components/forms/Contact/Contact';
import SubmitApplication from '../components/forms/SubmitApplication/SubmitApplication';
import ThankYou from '../components/ThankYou/ThankYou';
import {
    fetchUser
} from '../api/user';
import {
    validateEmail,
    validatePhone, validatePositiveNumber, validateWebsite,
    validateZip
} from '../components/forms/formValidations';

export const updateValue = async(
    event: BaseSyntheticEvent,
    target,
    setApplication
) => {
    const currentTarget = event ? event.currentTarget : target;
    let value = currentTarget.value;
    if ('checkbox' === currentTarget.getAttribute('type')) {
        if (false === currentTarget.checked) {
            value = null;
        }
    }
    const result = await updateApplicationValue(
        currentTarget.name,
        value
    );
    if (result.success) {
        setApplication(result);


        // :see_no_evil: just because we ran out of time and need a quick solution …
        // @ts-ignore
        if (window.savingIndicatorTimeout) {
            // @ts-ignore
            window.clearTimeout(window.savingIndicatorTimeout);
        }
        // @ts-ignore
        if (window.savingIndicatorTimeout2) {
            // @ts-ignore
            window.clearTimeout(window.savingIndicatorTimeout2);
        }
        const savingIndicator = document.getElementById('saving-indicator') || document.createElement('div');
        savingIndicator.innerText = 'Gespeichert';
        savingIndicator.id = 'saving-indicator';
        savingIndicator.style.position = 'fixed';
        savingIndicator.style.right = '10px';
        if (1023 >= window.innerWidth) { // tablet | mobile
            savingIndicator.style.top = '10px';
            savingIndicator.style.padding = '5px 10px';
        }  else {
            savingIndicator.style.bottom = '10px';
            savingIndicator.style.padding = '15px 30px';
        }
        savingIndicator.style.color = 'var(--turquoise)';
        savingIndicator.style.border = '1px solid var(--turquoise)';
        savingIndicator.style.borderRadius = '30px';
        savingIndicator.style.backgroundColor = 'var(--white)';
        savingIndicator.style.opacity = '0';
        savingIndicator.style.transition = 'opacity 200ms ease-out';
        document.body.appendChild(savingIndicator);
        window.setTimeout(
            () => {
                savingIndicator.style.opacity = '1';
            },
            100
        );
        // @ts-ignore
        window.savingIndicatorTimeout = window.setTimeout(
            () => {
                savingIndicator.style.opacity = '0';
            },
            3000
        );
        // @ts-ignore
        window.savingIndicatorTimeout2 = window.setTimeout(
            () => {
                document.body.removeChild(savingIndicator);
            },
            3200
        );
    }
};

const ApplicationPage = ({
    application,
    setApplication,
}) => {
    const [activeTab, setActiveTab,] = useState('company');

    // return to login if application does not exist
    useEffect(
        () => {
            (async() => {
                const user = await fetchUser();
                if (!user) {
                    navigate('/user/login-register');
                }

                if (!application) {
                    setApplication(await fetchApplication());
                }
            })();
        },
        [application,]
    );


    if (!application) {
        return <DefaultPage></DefaultPage>;
    }

    if (10 === application?.status) {
        return <DefaultPage showProfile>
            <ThankYou/>
        </DefaultPage>;
    }
    return (
        <DefaultPage showProfile>
            <Progress application={application}/>
            <Formik
                initialValues={application.data}
                onSubmit={() => {}}
                validate={((values: any) => {
                    const errors = {};
                    if (values.companyZipCode___1?.length &&!validateZip(values.companyZipCode___1)) {
                        errors['companyZipCode___1'] = 'Bitte geben Sie eine gültige Postleitzahl an';
                    }
                    if (values.companyPhone?.length && !validatePhone(values.companyPhone)) {
                        errors['companyPhone'] = 'Bitte geben Sie eine gültige Telefonnummer an';
                    }
                    if (values.companyNumberOfEmployees?.length && !validatePositiveNumber(values.companyNumberOfEmployees)) {
                        errors['companyNumberOfEmployees'] = 'Bitte geben Sie einen Wert größer null an';
                    }
                    if (values.companyWebsite___1?.length && !validateWebsite(values.companyWebsite___1)) {
                        errors['companyWebsite___1'] = 'Bitte geben Sie eine gültige URL an';
                    }
                    if (values.productWebsite___1?.length && !validateWebsite(values.productWebsite___1)) {
                        errors['productWebsite___1'] = 'Bitte geben Sie eine gültige URL an';
                    }
                    if (values.potentialCustomersCount?.length && !validatePositiveNumber(values.potentialCustomersCount)) {
                        errors['potentialCustomersCount'] = 'Bitte geben Sie einen Wert größer null an';
                    }
                    if (values.financesTurnover2021?.length && !validatePositiveNumber(values.financesTurnover2021)) {
                        errors['financesTurnover2021'] = 'Bitte geben Sie einen Wert größer null an';
                    }
                    if (values.financesTurnover2022?.length && !validatePositiveNumber(values.financesTurnover2022)) {
                        errors['financesTurnover2022'] = 'Bitte geben Sie einen Wert größer null an';
                    }
                    if (values.financesTurnover2023?.length && !validatePositiveNumber(values.financesTurnover2023)) {
                        errors['financesTurnover2023'] = 'Bitte geben Sie einen Wert größer null an';
                    }
                    if (values.contactPhone___1?.length && !validatePhone(values.contactPhone___1)) {
                        errors['contactPhone___1'] = 'Bitte geben Sie eine gültige Telefonnummer an';
                    }
                    if (values.contactEmail___1?.length && !validateEmail(values.contactEmail___1)) {
                        errors['contactEmail___1'] = 'Bitte geben Sie eine gültige E-Mail Adresse an';
                    }
                    return errors;
                })}
            >
                <Form>
                    <Tabs
                        stickyNav
                        active={activeTab}
                        tabs={[
                            <Tab
                                id="company"
                                label="Unternehmen">
                                <Company setActiveTab={setActiveTab}/>
                            </Tab>,
                            <Tab
                                id="innovation"
                                label="Innovation">
                                <Innovation setActiveTab={setActiveTab}/>
                            </Tab>,
                            <Tab
                                id="finances"
                                label="Kapital">
                                <Finances setActiveTab={setActiveTab}/>
                            </Tab>,
                            <Tab
                                id="potential"
                                label="Potential">
                                <Potential setActiveTab={setActiveTab}/>
                            </Tab>,
                            <Tab
                                id="documents"
                                label="Dokumente">
                                <Documents setActiveTab={setActiveTab}/>
                            </Tab>,
                            <Tab
                                id="contact"
                                label="Kontakt">
                                <Contact setActiveTab={setActiveTab}/>
                            </Tab>,
                            <Tab
                                id="submit"
                                label="Abschicken">
                                <SubmitApplication />
                            </Tab>,
                        ]}
                    />
                </Form>
            </Formik>
        </DefaultPage>
    );
};

const mapStateToProps = ({
    application,
}) => {
    return {
        application,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setApplication: ({
            application,
        }) => {
            return dispatch({
                type: 'SET_APPLICATION',
                payload: {
                    application,
                },
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApplicationPage);

