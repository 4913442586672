import * as React from 'react';
import TextInput from '../../formElements/TextInput/TextInput';
import {
    connect
} from 'react-redux';
import {
    updateValue
} from '../../../pages/application';
import FieldSet from '../FieldSet/FieldSet';
import DateInput from '../../formElements/DateInput/DateInput';
import Select from '../../formElements/Select/Select';
import TextArea from '../../formElements/TextArea/TextArea';
import RadioButtons from '../../formElements/RadioButtons/RadioButtons';
import NumberInput from '../../formElements/NumberInput/NumberInput';
import UrlInput from '../../formElements/UrlInput/UrlInput';
import CustomButton from '../../CustomButton/CustomButton';

const Company = ({
    application,
    setApplication,
    setActiveTab,
}) => {
    const onFieldBlur = async (
        event, target = null
    ) => {
        await updateValue(
            event,
            target,
            setApplication
        );
    };
    return <div className="">
        <TextInput
            label="Unternehmensname"
            name="companyName"
            onBlur={onFieldBlur}
        />
        <TextInput
            label="Branche"
            name="companyBranch"
            onBlur={onFieldBlur}
        />
        <FieldSet
            label="Unternehmensadresse"
            setApplication={setApplication}
            replicateDefaultValues={application?.data}
            fields={[
                <TextInput
                    label="Straße"
                    name="companyStreet"
                    onBlur={onFieldBlur}
                />,
                <TextInput
                    label="Hausnummer"
                    name="companyHouseNumber"
                    onBlur={onFieldBlur}
                />,
                <TextInput
                    label="Postleitzahl"
                    name="companyZipCode"
                    onBlur={onFieldBlur}
                />,
                <TextInput
                    label="Ort"
                    name="companyCity"
                    onBlur={onFieldBlur}
                />,
                <TextInput
                    label="Land"
                    name="companyCountry"
                    onBlur={onFieldBlur}
                />,
            ]}
        />
        <TextInput
            label="Unternehmenstelefonnummer"
            name="companyPhone"
            onBlur={onFieldBlur}
        />
        {/* TODO: value is not saved */}
        <DateInput
            label="Gründungsdatum"
            name="companyFoundationDate"
            onBlur={onFieldBlur}
        />
        <FieldSet
            setApplication={setApplication}
            label="Gründungsteam"
            description="Bitte nennen Sie jedes Gründungsmitglied einzeln unter Angabe der letzten relevanten Rollen und
Unternehmen"
            replicable
            replicableLabel="Weitere Person hinzufügen"
            replicateDefaultValues={application?.data}
            fields={[
                <TextInput
                    label="Titel (optional)"
                    name="companyFoundationTeamMemberTitle"
                    onBlur={onFieldBlur}
                />,
                <Select
                    label="Anrede"
                    name="companyFoundationTeamMemberSalutation"
                    onBlur={onFieldBlur}
                    options={[
                        {
                            value: '', label: 'Nicht ausgewählt',
                        },
                        {
                            value: 'w', label: 'Frau',
                        },
                        {
                            value: 'm', label: 'Herr',
                        },
                        {
                            value: 'd', label: 'Divers',
                        },
                    ]}
                />,
                <TextInput
                    label="Vorname"
                    name="companyFoundationTeamMemberFirstName"
                    onBlur={onFieldBlur}
                />,
                <TextInput
                    label="Nachname"
                    name="companyFoundationTeamMemberLastName"
                    onBlur={onFieldBlur}
                />,
                <TextArea
                    label="Letzte relevante Rollen im Unternehmen"
                    description="Bitte nennen Sie die letzten relevanten Tätigkeiten der angegebenen Person und in welchen
Unternehmen diese ausgeführt wurden (max. 150 Zeichen)"
                    name="companyFoundationTeamMemberRoles"
                    onBlur={onFieldBlur}
                    maxChars={150}
                />,
            ]}
        />
        <RadioButtons
            label="Managementteam"
            name="companyManagementTeamType"
            options={[
                {
                    value: 'same_as_foundation_team', label: 'Identisch zu Gründungsteam',
                },
                {
                    value: 'extends_foundation_team',
                    label: 'Identisch zu Gründungsteam aber weitere Personen im Managementteam',
                },
                {
                    value: 'different_team', label: 'Anderes Team als Gründungsteam',
                },
            ]}
            onChange={onFieldBlur}
        />
        {
            'same_as_foundation_team' !== application?.data.companyManagementTeamType &&
            <FieldSet
                setApplication={setApplication}
                description="Bitte nennen Sie jedes Managementmitglied einzeln unter Angabe der letzten relevanten Rollen
und Unternehmen"
                replicable
                replicableLabel="Weitere Person hinzufügen"
                replicateDefaultValues={application?.data}
                fields={[
                    <TextInput
                        label="Titel (optional)"
                        name="companyManagementTeamMemberTitle"
                        onBlur={onFieldBlur}
                    />,
                    <Select
                        label="Anrede"
                        name="companyManagementTeamMemberSalutation"
                        onBlur={onFieldBlur}
                        options={[
                            {
                                value: '', label: 'Nicht ausgewählt',
                            },
                            {
                                value: 'w', label: 'Frau',
                            },
                            {
                                value: 'm', label: 'Herr',
                            },
                            {
                                value: 'd', label: 'Divers',
                            },
                        ]}
                    />,
                    <TextInput
                        label="Vorname"
                        name="companyManagementTeamMemberFirstName"
                        onBlur={onFieldBlur}
                    />,
                    <TextInput
                        label="Nachname"
                        name="companyManagementTeamMemberLastName"
                        onBlur={onFieldBlur}
                    />,
                    <TextArea
                        label="Letzte relevante Rollen im Unternehmen"
                        description="Bitte nennen Sie die letzten relevanten Tätigkeiten der angegebenen Person und in welchen
Unternehmen diese ausgeführt wurden (max. 150 Zeichen)"
                        name="companyManagementTeamMemberRoles"
                        onBlur={onFieldBlur}
                        maxChars={150}
                    />,
                ]}
            />
        }
        <NumberInput
            label="Mitarbeiteranzahl"
            name="companyNumberOfEmployees"
            onBlur={onFieldBlur}
        />
        <FieldSet
            setApplication={setApplication}
            label="Webseiten"
            replicateDefaultValues={application?.data}
            description="Bitte füllen Sie mindestens eine der beiden unterstehenden Optionen aus"
            fields={[<UrlInput
                label="Unternehmenswebseite"
                name="companyWebsite"
                onBlur={onFieldBlur}
            />, <UrlInput
                label="Produktwebseite"
                name="productWebsite"
                onBlur={onFieldBlur}
            />,]}
        />

        {/* TODO: @Felix, der Button wird am unteren Seitenende zentriert */}
        <CustomButton
            label='Weiter zu "Innovation"'
            onClick={() => {
                setActiveTab('innovation');
            }}
        />
    </div>;
};

const mapStateToProps = ({
    application,
}) => {
    return {
        application,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setApplication: ({
            application,
        }) => {
            return dispatch({
                type: 'SET_APPLICATION',
                payload: {
                    application,
                },
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Company);


