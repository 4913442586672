export const restrictChars = (
    value: string, maxChars: number
) => {
    value = value.trim();
    if (value.length > maxChars) {
        return value.slice(
            0,
            maxChars
        );
    }
    return value;
};

export const restrictWords = (
    value: string, maxWords: number
) => {
    value = value.trim();
    let trimmed = '';
    let hasWords = 0;
    const words = value.split(/\s/g);
    for (const word of words) {
        // whitespace should not count as word
        if (0 === word.trim().length) {
            maxWords++;
        }
        if ('-' === word) { // should not be counted as word
            maxWords++;
        }
        trimmed += word;
        trimmed += value.slice(
            trimmed.length,
            trimmed.length + 1
        );
        hasWords++;
        if (hasWords === maxWords) {
            break;
        }
    }
    return trimmed;
};

export const countActualWords = (value: string) => {
    value = value.trim();
    let hasWords = 0;
    const parts = value.split(/\s/g);
    for (const part of parts) {
        // whitespace should not count as word
        if (0 === part.trim().length) {
            continue;
        }
        if ('-' === part) { // should not be counted as word
            continue;
        }
        hasWords++;
    }
    return hasWords;
};

