// import * as React from 'react';
// import Filter from '../../Filter/Filter';
// // @ts-ignore
// import * as styles from './Select.module.css';
//
// interface ISelect {
//     label: string,
//     name: string,
//     options: {value: string, label: string}[];
// }
//
// const Select = ({
//     label,
// }: ISelect) => {
//     return <div className={styles.select}>
//         <label>
//             <span>{label}:</span>
//             <Filter
//                 defaultValue={'b'}
//                 options={
//                     [{
//                         value: 'a', label: 'hello',
//                     }, {
//                         value: 'b', label: 'world',
//                     },]
//                 }/>
//         </label>
//     </div>;
// };
//
// export default Select;

import * as React from 'react';
import {
    ErrorMessage, Field, useFormikContext
} from 'formik';
import {
    BaseSyntheticEvent
} from 'react';
// @ts-ignore
import * as styles from './Select.module.css';

interface ISelect {
    label: string,
    value?: string,
    name: string,
    // eslint-disable-next-line no-unused-vars
    onBlur?: (event: BaseSyntheticEvent) => void,
    options: {value: string, label: string}[]
}

const Select = ({
    value, label, name, onBlur,
    options,
}: ISelect) => {
    const {
        initialValues,
    } = useFormikContext();
    const defaultValue = initialValues[name];
    return <div className={styles.select}>
        <label>
            <span>{label}:</span>
            <ErrorMessage
                className="invalid"
                name={name}
                component="div" />
            <Field
                as="select"
                onChange={onBlur}
                name={name}
                value={value}
                defaultValue={defaultValue}
            >
                {options.map(option => {
                    return <option
                        key={option.value}
                        value={option.value}>{option.label}</option>;
                })}
            </Field>
        </label>
    </div>;
};

export default Select;
