import * as React from 'react';

// @ts-ignore
import * as styles from './Progress.module.css';

interface IProgress {
    application: {
        data: any
    }
}

export const getRequiredKeysObject = function (application) {
    const requiredKeys = {
        'companyName': 'Unternehmen: Unternehmensname',
        'companyBranch': 'Unternehmen: Branche',
        'companyStreet': 'Unternehmen: Straße',
        'companyHouseNumber': 'Unternehmen: Hausnummer',
        'companyZipCode': 'Unternehmen: Postleitzahl',
        'companyCity': 'Unternehmen: Stadt',
        'companyCountry': 'Unternehmen: Land',
        'companyPhone': 'Unternehmen: Unternehmenstelefonnummer',
        'companyFoundationDate': 'Unternehmen: Gründungsdatum',
        'companyFoundationTeamMemberSalutation': 'Unternehmen: Gründungsmitglied Anrede',
        'companyFoundationTeamMemberFirstName': 'Unternehmen: Gründungsmitglied Vorname',
        'companyFoundationTeamMemberLastName': 'Unternehmen: Gründungsmitglied Nachname',
        'companyFoundationTeamMemberRoles': 'Unternehmen: Gründungsmitglied Rollen',
        'companyManagementTeamType': 'Unternehmen: Managementteam',
        'companyNumberOfEmployees': 'Unternehmen: Mitarbeiteranzahl',

        'innovationName': 'Innovation: Name der Innovation',
        'innovationShortDescription': 'Innovation: Kurbeschreibung',
        'innovationDescription': 'Innovation: Ausführliche Beschreibung',
        'innovationProtected': 'Innovation: Patent / IP geschützt',
        'innovationAdvantages': 'Innovation: Kundennutzen',
        'innovationUSP': 'Innovation: Alleinstellungsmerkmal',

        'financesFundingReceived': 'Kapital: Finanzierung erhalten',
        'financesTurnover2021': 'Kapital: Umsatzlevel 2021',
        'financesTurnover2022': 'Kapital: Umsatzlevel 2022',
        'financesTurnover2023': 'Kapital: Umsatzlevel 2023',

        'potentialCustomersCount': 'Potential: Kundenanzahl',
        'potentialMarketPotential': 'Potential: Marktpotential',
        'potentialCompetition': 'Potential: Wettbewerber',

        'documentsPitchDeck': 'Dokumente: Pitchdeck',
        'documentsLogo': 'Dokumente: Logo',
        'documentsFoundationTeamPhotos': 'Dokumente: Foto des Gründungsteams',
        // 'documentsTeamPhotos': 'Dokumente: Foto des Teams',
        'documentsProductPhotos': 'Dokumente: Foto der Innovation',

        'contactSalutation': 'Kontakt: Anrede',
        'contactFirstName': 'Kontakt: Vorname',
        'contactLastName': 'Kontakt: Nachname',
        'contactEmail': 'Kontakt: E-Mail',
        'contactPhone': 'Kontakt: Telefon',

        'submitLegals': 'Abschicken: Teilnahmebedingungen',

        // TODO: companyWebsite or productWebsite
    } as any;

    if ('extends_foundation_team' === application.companyManagementTeamType || 'different_team' === application.companyManagementTeamType) {
        requiredKeys.companyManagementTeamMemberSalutation = 'Unternehmen: Team Mitglied Anrede';
        requiredKeys.companyManagementTeamMemberFirstName = 'Unternehmen: Team Mitglied Vorname';
        requiredKeys.companyManagementTeamMemberLastName = 'Unternehmen: Team Mitglied Nachname';
        requiredKeys.companyManagementTeamMemberRoles = 'Unternehmen: Team Mitglied Rollen';
    }
    if ('y' === application.innovationProtected) {
        requiredKeys.innovationProtectionDescription = 'Innovation: Erklärung Patent';
    }
    if ('y' === application.financesFundingReceived) {
        requiredKeys.financesFundingAmount = 'Kapital: Höhe der Finanzierung';
        requiredKeys.financesFundingFinancier = 'Kapital: Finanzierungsgeber';
    }
    if (!application.companyWebsite___1?.length && !application.productWebsite___1?.length) {
        requiredKeys.___one_website = 'Unternehmen: Unternehmens- oder Produktwebseite';
    }
    return requiredKeys;
};


export const calculatePercentage = (applicationData) => {
    const requiredKeysObject = getRequiredKeysObject(applicationData);
    const requiredKeys = Object.keys(requiredKeysObject);
    if (!applicationData) {
        return {
            percentage: 0,
            missingFields: [],
        };
    }
    // get keys of all filled out fields
    const filledOutKeys = Object.entries(applicationData).filter(entry => {
        return !!entry[1];
    }).map(entry => {
        return entry[0];
    })
        // clean up for checkbox fields
        .map(field => {
            return field.split('[')[0];
        });
    // remove replica keys
    // @ts-ignore
    const filledOutKeysWithoutReplicates = [...new Set(filledOutKeys.map(key => {
        return key.split('___')[0];
    })),];

    const missingFields = requiredKeys.filter(key => {
        return !filledOutKeysWithoutReplicates.includes(key);
    });

    // now get intersection of required and filled out fields
    const filledOutKeysThatAreRequired = filledOutKeysWithoutReplicates.filter(value => {
        return requiredKeys.includes(value);
    });
    if (0 === filledOutKeysThatAreRequired.length) {
        return {
            percentage: 0,
            missingFields: [],
        };
    }
    return {
        percentage: Math.ceil((requiredKeys.length - missingFields.length) / requiredKeys.length * 100),
        missingFields,
    };
};

const Progress = ({
    application,
}: IProgress) => {
    const {
        percentage,
    } = calculatePercentage(application?.data);
    return <div className={styles.progress}>
        <span>Gesamtfortschritt</span>
        <div className={styles.progressIndicator}>
            <div
                className={styles.progressIndicatorInner}
                style={{
                    width: `${percentage}%`,
                }}/>
            <div
                className={styles.progressIndicatorValue}
                data-progress={percentage}>{percentage} %
            </div>
        </div>
    </div>;
};

export default Progress;
