import * as React from 'react';
import CustomButton from '../../CustomButton/CustomButton';
import {
    useEffect, useState
} from 'react';
// @ts-ignore
import * as styles from './FieldSet.module.css';
import {
    updateValue
} from '../../../pages/application';

interface IFieldSet {
    fields: React.ReactElement[]
    label?: string,
    description?: string
    replicable?: boolean
    replicableLabel?: string
    replicateDefaultValues?: any[]
    setApplication: any
}

const FieldSet = ({
    fields,
    label,
    description,
    replicable = false,
    replicableLabel,
    replicateDefaultValues,
    setApplication,
}: IFieldSet) => {
    const [numberOfReplicates, setNumberOfReplicates,] = useState(1);
    const [actualFields, setActualFields,] = useState([]);

    useEffect(
        () => {
            if (replicateDefaultValues) {
                let storedReplicates = 1;

                const fieldNames = fields.map(field => {
                    return field.props.name;
                });
                const containsReplicate = () => {
                    for (const fieldName of fieldNames) {
                        if (Object.keys(replicateDefaultValues).includes(`${fieldName}___${storedReplicates + 1}`)) {
                            storedReplicates = storedReplicates + 1;
                            containsReplicate();
                        }
                    }
                };
                containsReplicate();

                setNumberOfReplicates(storedReplicates);
            }
        },
        [replicateDefaultValues,]
    );

    useEffect(
        () => {
            const updatedActualFields = [];
            for (let i = 0; i < numberOfReplicates; i++) {
                if (0 < i) { // add divider between replicates
                    updatedActualFields.push(<div className={styles.replicateDivider}/>);
                }
                for (const field of fields) {
                    updatedActualFields.push({
                        ...field,
                        props: {
                            ...field.props,
                            name: `${field.props.name}___${(i + 1)}`,
                            defaultValue: field.props.defaultValue
                                ? field.props.defaultValue
                                : (replicateDefaultValues ? replicateDefaultValues[`${field.props.name}___${(i + 1)}`] : null)
                            ,
                        },
                    });
                }
            }
            setActualFields(updatedActualFields);
        },
        [numberOfReplicates,]
    );

    const onFieldBlur = async (
        event, target = null
    ) => {
        await updateValue(
            event,
            target,
            setApplication
        );
    };

    return <fieldset>
        {label && <span>{label}</span>}
        {
            description &&
            <p>{description}</p>
        }
        <div className="grid-offset-1--desktop grid-offset-1--tablet grid-gap--mobile">

            {actualFields.map(field => {
                if (field.props.onBlur) {
                    field.props.onBlur = onFieldBlur; // need to overwrite function here, because of the way we treat the fiels the original function is not working anymore …
                }
                return field;
            })}

            {
                replicable && replicableLabel &&
                <CustomButton
                    label={replicableLabel}
                    style="link"
                    onClick={() => {
                        setNumberOfReplicates(numberOfReplicates + 1);
                    }}
                />
            }
        </div>
    </fieldset>;
};

export default FieldSet;
