import * as React from 'react';
import {
    ErrorMessage, Field, useFormikContext
} from 'formik';
import {
    BaseSyntheticEvent
} from 'react';
// @ts-ignore
import * as styles from './UrlInput.module.css';

interface IUrlInput {
    label: string,
    value?: string,
    defaultValue?: string,
    name: string,
    // eslint-disable-next-line no-unused-vars
    onBlur?: (event: BaseSyntheticEvent) => void,
}

const UrlInput = ({
    label, value, defaultValue, name, onBlur,
}: IUrlInput) => {
    const {
        setFieldTouched, validateField, errors,
    } = useFormikContext();
    const handleUpdate = (e) => {
        setFieldTouched(name);
        validateField(name);
        if (!errors[name]) {
            onBlur(e);
        }
    };
    return <div className={styles.urlInput}>
        <label>
            <span>{label}:</span>
            <ErrorMessage
                className="invalid"
                name={name}
                component="div" />
            <Field
                type="url"
                name={name}
                defaultValue={defaultValue}
                value={value}
                onBlur={handleUpdate}
            />
        </label>
    </div>;
};

export default UrlInput;
