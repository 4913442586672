import * as React from 'react';
import {
    logout
} from '../../helpers/user';
import CustomButton from '../CustomButton/CustomButton';

// @ts-ignore
import * as styles from './ThankYou.module.css';

const ThankYou = () => {
    return <div className={`${styles.container} grid-8--desktop grid-offset-2--desktop grid-8--tablet grid-2--mobile`}>
        <h1>Vielen Dank!</h1>
        <p>
            Ihre Bewerbung wurde erfolgreich übermittelt. Bei Fragen rund um Ihre Bewerbung wenden Sie sich bitte an <a
                style={{whiteSpace: 'nowrap',}}
                href="mailto:innovationspreis@focus-magazin.de">innovationspreis@focus-magazin.de
            </a>.
        </p>
        <CustomButton
            onClick={() => {
                logout();
            }}
            label="Logout"/>
    </div>;
};

export default ThankYou;
