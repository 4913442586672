import * as React from 'react';
import {
    connect
} from 'react-redux';
import {
    updateValue
} from '../../../pages/application';
import TextArea from '../../formElements/TextArea/TextArea';
import CustomButton from '../../CustomButton/CustomButton';
import NumberInput from '../../formElements/NumberInput/NumberInput';

const Potential = ({
    setApplication,
    setActiveTab,
}) => {
    const onFieldBlur = async (
        event, target = null
    ) => {
        await updateValue(
            event,
            target,
            setApplication
        );
    };
    return <div className="">
        <NumberInput
            label="Kundenanzahl"
            name="potentialCustomersCount"
            onBlur={onFieldBlur}
        />

        <TextArea
            label="Marktpotential"
            description="Bitte beschreiben Sie das Marktpotential Ihres Produktes / Ihrer Dienstleistung kurz in max. 100 Wörtern"
            name="potentialMarketPotential"
            onBlur={onFieldBlur}
            maxWords={100}
        />

        <TextArea
            label="Wettbewerber"
            description="Bitte beschreiben Sie Ihre Wettbewerber in max. 100 Wörter"
            name="potentialCompetition"
            onBlur={onFieldBlur}
            maxWords={100}
        />


        {/* TODO: @Felix, der Button wird am unteren Seitenende zentriert */}
        <CustomButton
            label='Weiter zu "Dokumente"'
            onClick={() => {
                setActiveTab('documents');
            }}
        />
    </div>;
};

const mapStateToProps = ({
    application,
}) => {
    return {
        application,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setApplication: ({
            application,
        }) => {
            return dispatch({
                type: 'SET_APPLICATION',
                payload: {
                    application,
                },
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Potential);


