export const validateZip = (value) => {
    return value && null !== value.match(/^[0-9]{4,5}$/);
};

export const validatePhone = (value) => {
    return value && null !== value.match(/^[\s()+-/0-9]*$/);
};

export const validateEmail = (value) => {
    return value && null !== value.trim().match(/^[^@]+@[^@.]+\.[^@.]{2,64}/);
};

export const validateWebsite = (
    value, weak = true
) => {
    if (weak) { // do not validate if starts with http of https
        return value && null !== value.trim().match(/^[^@]*\..{1,64}/) && null === value.trim().match(/\s/);
    }
    return value && null !== value.trim().match(/^http[s]?:\/\/.*\..{1,64}/) && null === value.trim().match(/\s/);
};

export const validatePositiveNumber = (value) => {
    const parsedNumber = parseInt(
        value,
        10
    );
    return !!value && !isNaN(parsedNumber) && (0 < parsedNumber);
};
