import * as React from 'react';
import {
    connect
} from 'react-redux';
import {
    updateValue
} from '../../../pages/application';
import CustomButton from '../../CustomButton/CustomButton';
import CheckboxGroup from '../../formElements/CheckboxGroup/CheckboxGroup';
import Alert from '../../Alert/Alert';
import {
    calculatePercentage, getRequiredKeysObject
} from '../../Progress/Progress';
import {
    useState
} from 'react';
import {
    submitApplication
} from '../../../api/application';

const SubmitApplication = ({
    application,
    setApplication,
}) => {
    const {
        percentage,
        missingFields,
    } = calculatePercentage(application?.data);
    const submitEnabled = 100 === percentage;

    const [alert, setAlert,] = useState(null);

    const onFieldBlur = async (
        event, target = null
    ) => {
        await updateValue(
            event,
            target,
            setApplication
        );
    };


    if (alert) {
        return <Alert
            message={alert}
            dismiss={() => {
                setAlert(null);
            }}
            dismissLabel="Ok"
        />;
    }

    return <div className="">
        <CheckboxGroup
            name="submitLegals"
            onChange={onFieldBlur}
            defaultValueKey={'submitLegals'}
            defaultValueData={application?.data}
            options={[{
                value: 'terms', label: 'Ich stimme den <a href="/teilnahmebedingungen">Teilnahmebedingungen</Link> zu.',
            },]}

        />
        <br/>

        <p>Weitere Informationen zur Verarbeitung Ihrer personenbezogenen Daten, insbesondere zu Ihren Rechten auf Grund
            der Verarbeitung, können Sie innerhalb unserer Datenschutzerklärung einsehen. Diese ist über diesen Link
            abrufbar: <a
            href="https://www.focus-magazin.de/datenschutz-web#hbmpp-toc-II-section-30941"
            target="_blank">Datenschutz
        </a>
        </p>

        {
            100 !== percentage && <>
                <p>Es fehlen noch folgende Felder:</p>
                <ul>
                    {
                        missingFields && missingFields.map(field => {
                            return <li style={{
                                marginLeft: 20,
                            }}>{getRequiredKeysObject(application.data)[field]}
                            </li>;
                        })
                    }
                </ul>
            </>
        }
        <br/>
        {
            false === submitEnabled && <>
                <p>Bitte füllen Sie alle Felder aus, um die Bewerbung abschicken zu können.</p>
                <CustomButton
                    disabled
                    label="Bewerbung abschicken"
                    onClick={() => {
                        setAlert('Bitte füllen Sie alle Felder aus, um die Bewerbung abschicken zu können.');
                    }}/>
            </>
        }
        {
            submitEnabled && <>
                <CustomButton
                    label="Bewerbung final abschicken"
                    onClick={async () => {
                        const result = await submitApplication();
                        setApplication(result);
                    }}/>
            </>
        }
    </div>;
};

const mapStateToProps = ({
    application,
}) => {
    return {
        application,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setApplication: ({
            application,
        }) => {
            return dispatch({
                type: 'SET_APPLICATION',
                payload: {
                    application,
                },
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubmitApplication);


