import * as React from 'react';
import {
    ErrorMessage, Field, useFormikContext
} from 'formik';
import {
    BaseSyntheticEvent
} from 'react';
// @ts-ignore
import * as styles from './NumberInput.module.css';

interface ITextInput {
    label: string,
    value?: string,
    name: string,
    // eslint-disable-next-line no-unused-vars
    onBlur?: (event: BaseSyntheticEvent) => void,
}

const NumberInput = ({
    label, value, name, onBlur,
}: ITextInput) => {
    const {
        setFieldTouched, validateField, errors, initialValues,
    } = useFormikContext();
    const handleUpdate = (e) => {
        setFieldTouched(name);
        validateField(name);
        if (!errors[name]) {
            onBlur(e);
        }
    };
    const defaultValue = initialValues[name];
    return <div className={styles.numberInput}>
        <label>
            <span>{label}:</span>
            <ErrorMessage
                className="invalid"
                name={name}
                component="div" />
            <Field
                type="number"
                name={name}
                defaultValue={defaultValue}
                value={value}
                onBlur={handleUpdate}
                onKeyDown={(e: BaseSyntheticEvent) => {
                    // unfortunately we can't rely on
                    // the number input because if Firefox you can
                    // still enter letters. Even worse - if the value is not valid Firefox won't accept
                    // the input at all and return an empty value, thus rendering any additional JS validation worthless.
                    // So best way is to prevent all non numeric characters, while still allowing things like Enter, Backspace, Del etc.
                    if (![
                        '0',
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                        'Backspace',
                        'Delete',
                        'Enter',
                        'Tab',
                        'ArrowDown',
                        'ArrowUp',
                    // @ts-ignore
                    ].includes(e.nativeEvent.key)) {
                        e.preventDefault();
                        return false;
                    }
                }}
                min={1}
            />
        </label>
    </div>;
};

export default NumberInput;
