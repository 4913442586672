import * as React from 'react';
import {
    ErrorMessage
} from 'formik';
// @ts-ignore
import * as styles from './CheckboxGroup.module.css';

interface ICheckboxGroup {
    label?: string,
    description?: string,
    name: string,
    defaultValueData?: string[]
    defaultValueKey?: string
    options: {
        value: string,
        label: string
    }[],
    // eslint-disable-next-line no-unused-vars
    onChange: (e) => void
}


const CheckboxGroup = ({
    label, description, name, defaultValueData, defaultValueKey, options, onChange,
}: ICheckboxGroup) => {
    return <div className={styles.checkboxGroup}>
        {label && <span>{label}:</span>}
        {description &&  <span>{description}</span>}
        <ErrorMessage
            className={`invalid ${styles.error}`}
            name={name}
            component="div" />
        {
            options.map(option => {
                return <label
                    key={option.value}
                    className={styles.label}>
                    <input
                        className={styles.checkbox}
                        type="checkbox"
                        name={`${name}[${option.value}]`}
                        value={option.value}
                        checked={defaultValueData && option.value === defaultValueData[`${defaultValueKey}[${option.value}]`]}
                        onChange={onChange}

                    />
                    <div className={styles.checkmark}/>
                    <span dangerouslySetInnerHTML={{
                        __html: option.label,
                    }}/>
                </label>;
            })
        }
    </div>;
};

export default CheckboxGroup;
