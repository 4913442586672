import * as React from 'react';
import {
    ErrorMessage, Field, useFormikContext
} from 'formik';
import {
    BaseSyntheticEvent, useRef, useState
} from 'react';
// @ts-ignore
import * as styles from './TextArea.module.css';
import {
    countActualWords,
    restrictChars, restrictWords
} from '../../../helpers/text';

interface ITextInput {
    label?: string,
    description?: string,
    name: string,
    maxChars?: number,
    maxWords?: number,
    // eslint-disable-next-line no-unused-vars
    onBlur?: (event: BaseSyntheticEvent, target) => void,
    placeholder?: string
}

const TextArea = ({
    label, name, onBlur, description, maxChars, maxWords, placeholder,
}: ITextInput) => {
    const textAreaWrapper = useRef(null);
    const {
        setFieldTouched, validateField, errors, initialValues, setFieldValue,
    } = useFormikContext();
    const handleUpdate = (e) => {
        setFieldTouched(name);
        validateField(name);
        if (maxChars) {
            e.target.value = restrictChars(
                e.target.value,
                maxChars
            );
        }
        if (maxWords) {
            e.target.value = restrictWords(
                e.target.value,
                maxWords
            );
        }

        if (!errors[name]) {
            onBlur(
                null,
                e.target
            );
        }
    };
    const defaultValue = initialValues[name];

    const [tempValue, setTempValue,] = useState(defaultValue);

    const handleKeyDown = (e) => {
        if (maxWords) {
            const valueBeforeRestriction = e.target.value;
            const restrictedValue = restrictWords(
                valueBeforeRestriction,
                maxWords
            );
            setTempValue(restrictedValue);
            if (countActualWords(valueBeforeRestriction) > maxWords) {
                setFieldValue(
                    name,
                    restrictedValue
                );
            }
        } else if (maxChars) {
            const valueBeforeRestriction = e.target.value;
            const restrictedValue = restrictChars(
                valueBeforeRestriction,
                maxChars
            );
            setTempValue(restrictedValue);
            if (restrictedValue.length > valueBeforeRestriction.length) {
                setFieldValue(
                    name,
                    restrictedValue
                );
            }
        }

        // if (maxWords && tempValue) {
        //     let words = 0;
        //
        //     if (null !== (tempValue.match(/\S+/g))) {
        //         words = tempValue.match(/\S+/g).length;
        //     }
        //     console.log(
        //         'MAX WORDS',
        //         words,
        //         maxWords
        //     );
        //     if (words > maxWords) {
        //         // Split the string on first x words and rejoin on spaces
        //         const trimmed = tempValue.split(
        //             /\s+/,
        //             maxWords
        //         );
        //         setmaxChars(trimmed.join(' ').length);
        //         setTempValue(trimmed.slice(
        //             0,
        //             maxWords
        //         ).join(' '));
        //         e.preventDefault();
        //         return false;
        //     } else {
        //         setmaxChars(10000);
        //     }
        // }
    };
    return <div className={styles.textArea}>
        <label ref={textAreaWrapper}>
            {label && <span>{label}:</span>}
            {description && <p className={styles.description}>{description}</p>}
            <ErrorMessage
                className="invalid"
                name={name}
                component="div"/>
            <Field
                component="textarea"
                placeholder={placeholder}
                name={name}
                defaultValue={tempValue}
                onBlur={handleUpdate}
                onKeyUp={handleKeyDown}
                maxLength={maxChars}
            />
            {
                maxChars && <span className={tempValue && tempValue.length >= maxChars? styles.maxLengthExceeded : ''}>{tempValue?.length || 0} / {maxChars} Zeichen</span>
            }
            {
                maxWords && <span className={tempValue && tempValue.trim().split(/\s/g).filter(w => {
                    return w;
                }).length >= maxWords? styles.maxLengthExceeded : ''}>{tempValue?.trim().split(/\s/g).filter(w => {
                        return w;
                    }).length || 0} / {maxWords} Wörter
                </span>
            }
        </label>
    </div>;
};

export default TextArea;
