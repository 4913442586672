import * as React from 'react';
import {
    ErrorMessage, Field, useFormikContext
} from 'formik';
// @ts-ignore
import * as styles from './RadioButtons.module.css';

const RadioButtons = ({
    label, name, options, onChange,
}) => {
    const {
        setFieldTouched, validateField, errors,  setFieldValue,
    } = useFormikContext();
    const handleUpdate = (e) => {
        setFieldTouched(name);
        validateField(name);
        if (!errors[name]) {
            onChange(e);
        }
    };

    return <div className={styles.radioButtons}>
        <div>
            <span>{label}:</span>
            <br/>
            <ErrorMessage
                className="invalid"
                name={name}
                component="div" />
            {
                options.map(option => {
                    return <label key={option.value}>
                        <Field
                            type="radio"
                            name={name}
                            value={option.value}
                            onChange={(e) => {
                                setFieldValue(
                                    name,
                                    option.value
                                );
                                handleUpdate(e);
                            }}
                            // onChange={handleUpdate}
                        /><span>{option.label}</span>
                    </label>;
                })
            }
        </div>
    </div>;
};

export default RadioButtons;
